<template>
  <div>
    <b-modal
      id="created-order"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ChangeDateOfOrder')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          :label="$t('Orders')"
        >
          <vue-select
            v-model="form.orderItems"
            :options="orderItems"
            multiple
            label="orderNumber"
            :placeholder="$t('Select')"
          />
          <p
            v-if="form.orderItems.length == 0 && buttonSubmited"
            style="color:red;"
          >
            {{ $t('OrderNr') }}
          </p>
        </b-form-group>
        <b-form-group
          :label="$t('Date')"
          style="margin-top: 5px"
        >
          <date-picker
            v-model="form.newCreatedTime"
            style="width:100%"
            type="date"
            format="YYYY-MM-DD"
            value-type="format"
            :placeholder="$t('SelectD')"
          />
          <p
            v-if="form.newCreatedTime == null && buttonSubmited"
            style="color:red;"
          >
            {{ $t('ChooseDate') }}
          </p>
        </b-form-group>
        <b-form-group
          :label="$t('Notes')"
          style="margin-top: 5px"
        >
          <b-form-textarea
            v-model="form.notes"
            style="width:100%"
            :placeholder="$t('Write...')"
          />
        </b-form-group>

      </b-form>

      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('VacationSubmit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import moment from 'moment-timezone';

export default {
  components: {
    DatePicker,
  },
  props: ['orderItems'],
  data() {
    return {
      buttonSubmited: false,
      form: {
        newCreatedTime: null,
        notes: null,
        orderItems: [],
      },
    }
  },
  watch: {
    orderItems(value) {
      if (value) {
        this.form.orderItems = value
      }
    },
  },
  methods: {
    onSubmit() {
      this.buttonSubmited = true
      if (this.form.orderItems.length == 0 || this.form.newCreatedTime == null) {
        return;
      }
      const orders = this.form.orderItems.map((item) => { return item.orderId })
      const objekti = {
        ...this.form,
        orderItems: orders,
      }
      this.$emit('changeDate', objekti)
      this.$refs.modal.hide()
      this.resetForm()
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {
      this.buttonSubmited = false
      this.form.notes = null
      this.form.newCreatedTime = null
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}
</style>
