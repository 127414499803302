<template>
  <div>
    <b-modal
      id="modal-edit-order"
      ref="modal"
      header-class="headerModal"
      shadow
      :title="$t('Edit') + ' ' + orderNumber"
      hide-footer
      size="m"
      @close="onCancel"
    >
      <template #title>
        <strong style="color: #242f6e;">{{ `${$t('Edit')} ${orderNumber}` }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #242f6e;height: 60px;"
          class="fa fa-close"
          @click="onCancel"
        />
      </template>
      <b-tabs
        v-model="tabIndex"
      >
        <b-tab>
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("Order") }}</strong>
          </template>
          <b-form>
            <b-form-group
              id="input-group-1"
              :label="`${$t('OrderT')}:`"
              label-for="input-1"
            >
              <vue-select
                v-model="getOrder.orderType"
                :options="getOrderTypes"
                label="text"
                :reduce="(e) => e.value"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>

            <b-form-group
              id="input-group-2"
              :label="`${$t('OrderNumber')}`"
              label-for="input-2"
            >
              <b-form-input
                id="input-1"
                v-model="getOrder.orderNumber"
              />
            </b-form-group>
          </b-form>
          <span style="color: red">{{ $t('DuplicateOrder') }}</span>
          <b-form-checkbox
            id="checkbox-1"
            v-model="getOrder.pickUpInStore"
            style="margin-top: 10px;"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
          >
            {{ $t('PickUpInStore') }}
          </b-form-checkbox>
          <pre />

          <b-form-group
            id="input-group-1"
            :label="`${$t('Description')}:`"
            label-for="input-1"
          >
            <b-form-textarea
              id="input-1"
              v-model="getOrder.description"
              aria-describedby="input-1-live-feedback"
            />
            <b-form-invalid-feedback id="input-1-live-feedback">{{
              $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
          </b-form-group>
          <PRE />
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("ShippmentAndClient") }}</strong>
          </template>
          <b-form-group
            :label="$t('Client')"
          >
            <div>
              <div class="black">
                <b-icon-search
                  v-if="searchClients == ''"
                  class="search1"
                  style=" position: absolute; left: 5px; top: 6px"
                />
                <b-icon-x-circle
                  v-else-if="clientId != '00000000-0000-0000-0000-000000000000'"
                  class="search1"
                  style="position: absolute; margin-top: -6px; right: 14px; top: 12px; cursor: pointer"
                  @click="removeLists"
                />
                <input
                  v-model="searchClients"
                  autocomplete="off"
                  type="text"
                  :placeholder="$t('ClientName')"
                  :style="getFirstNames.length > 0 ? 'border-radius: 8px 8px 8px 8px' : 'border-radius: 8px 8px 8px 8px; padding-left: 25px;'"
                  style="width:100%"
                  @keyup="inputChanged"
                  @keydown.down="onArrowDown"
                  @keydown.up="onArrowUp"
                >
              </div>
              <div
                v-if="getFirstNames.length > 0"
                ref="scrollContainer"
                class="dropdrop"
                :style="getFirstNames.length > 7 ? 'height: 210px' : 'height: auto'"
              >
                <div
                  v-for="(result, index) in getFirstNames"
                  :key="index"
                  ref="options"
                  style="cursor: pointer"
                  class="search-item"
                  :class="{ 'is-active': index === arrowCounter }"
                  @click="searchByOn(result.clientId);"
                >
                  <p style="margin: 0">
                    {{ result.nameToDisplay }}
                  </p>
                </div>
              </div>
            </div>
          </b-form-group>
          <p
            v-if="clientId == '00000000-0000-0000-0000-000000000000'"
            style="color:red;"
          >
            {{ $t('EmptyClient') }}
          </p>
          <div style="display:flex;gap:10px;padding-top: 10px">
            <b-form-group
              :label="$t('ShippingCost')"
            >
              <b-form-input
                v-model.number="shippings.cost"
                type="number"
                :placeholder="$t('Write...')"
              />
              <p
                v-if="((shippings.cost === '' || shippings.cost < 0) && (shippings.accountId !== null || shippings.currency !== null || shippings.notes !== '' || shippings.paidAmount !== ''))"
                style="color:red"
              >{{ $t('FillData') }}</p>
            </b-form-group>
            <b-form-group
              :label="$t('PaidAmount')"
            >
              <b-form-input
                v-model.number="shippings.paidAmount"
                type="number"
                :placeholder="$t('Write...')"
              />
              <p
                v-if="((shippings.paidAmount === '' || shippings.paidAmount < 0) && (shippings.accountId !== null || shippings.currency !== null || shippings.notes !== '' || shippings.cost !== '' || shippings.cost < 0))"
                style="color:red"
              >{{ $t('FillData') }}</p>
            </b-form-group>
          </div>
          <div style="padding-top: 10px">
            <b-form-group
              :label="$t('Account')"
            >
              <vue-select
                v-model="shippings.accountId"
                :options="getAccountDetails"
                label="name"
                :reduce="(e) => e.accountId"
                :placeholder="$t('Select')"
              />
            </b-form-group>
            <p
              v-if="(shippings.accountId == null && (shippings.notes !== '' || shippings.currency !== null || shippings.cost !== '' || shippings.cost < 0 || shippings.paidAmount !== '' || shippings.paidAmount < 0))"
              style="color:red"
            >{{ $t('FillData') }}</p>
            <b-form-group
              :label="$t('Notes')"
            >
              <b-form-textarea
                v-model.number="shippings.notes"
                :placeholder="$t('Write...')"
              />
            </b-form-group>
            <p
                v-if="(shippings.notes === '' && (shippings.accountId !== null || shippings.currency !== null || shippings.cost !== '' || shippings.cost < 0 || shippings.paidAmount !== '' || shippings.paidAmount < 0))"
                style="color:red"
              >{{ $t('FillData') }}</p>
          </div>
        </b-tab>
        <b-tab style="padding: 5px;">
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("Files") }}</strong>
          </template>
          <div
            v-if="getimagesOrder.length > 0"
          >
            <div
              v-for="(image, i) in getimagesOrder"
              :key="i"
            >
              <div
                v-if="image.fileType ==='Scetch'"
                class="files"
                style="display: flex;align-items: center; gap: 30px;"
              >
                <span style="color: #262E6C; font-weight: bold; width: 100px;">{{ $t("Sketchs") }}</span>
                <div>
                  <img
                    v-b-tooltip.hover
                    :title="$t('ClickToViewSketchPhoto') +' '+ image.fileName.replace(/^\d+-/, '')"
                    class="image"
                    style="width: 100px;"
                    :src="image.url"
                    alt="Not an image"
                    @click="index = i"
                  >
                  <b-icon-x-circle
                    :key="i"
                    style="margin-left: 25px; margin-right: 5px;"
                    @click="handleDeleteClick(image.fileDataId)"
                  />
                  <button
                    style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;"
                    @click="downloadFile(image.url)"
                  >
                    <b-icon-download style="color: white;" />
                  </button>
                </div>
              </div>
              <div
                v-if="image.fileType ==='Contract'"
                class="files"
                style="display: flex;align-items: center; gap: 30px; padding-top: 30px;"
              >
                <span style="color: #262E6C; font-weight: bold;width: 100px;">{{ $t("Contracts") }}</span>
                <div>
                  <img
                    v-b-tooltip.hover
                    style="width: 100px;"
                    :title="$t('ClickToViewSketchPhoto') +' '+ image.fileName.replace(/^\d+-/, '')"
                    class="image"
                    alt="Not an image"
                    :src="image.url"
                    @click="index = i"
                  >
                  <b-icon-x-circle
                    :key="i"
                    style="margin-left: 25px; margin-right: 5px;"
                    @click="handleDeleteClick(image.fileDataId)"
                  />
                  <button
                    style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;"
                    @click="downloadFile(image.url)"
                  >
                    <b-icon-download style="color: white;" />
                  </button>
                </div>
              </div>
              <div
                v-if="image.fileType ==='OtherDocument'"
                class="files"
                style="display: flex;align-items: center; gap: 30px; padding-top: 30px;"
              >
                <span style="color: #262E6C; font-weight: bold;">{{ $t("OtherDocuments") }}</span>
                <div>
                  <img
                    v-b-tooltip.hover
                    style="width: 100px;"
                    :title="$t('ClickToViewSketchPhoto') +' '+ image.fileName.replace(/^\d+-/, '')"
                    class="image"
                    alt="Not an image"
                    :src="image.url"
                    @click="index = i"
                  >
                  <b-icon-x-circle
                    :key="i"
                    style="margin-left: 25px; margin-right: 5px;"
                    @click="handleDeleteClick(image.fileDataId)"
                  />
                  <button
                    style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;"
                    @click="downloadFile(image.url)"
                  >
                    <b-icon-download style="color: white;" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            style="padding: 10px; background-color: gainsboro;text-align: center; font-weight: 500;"
          >
            {{ $t('NoSketchPhoto') }}
          </div>
          <vuegalleryslideshow
            :images="getimagesOrder"
            :index="index"
            @close="index = null"
          />
          <div style="display: flex;flex-direction: column;">
            <div style="display: flex;">
              <AddFile
                ref="addFile1"
                :folder="'MaterialVariantPhoto'"
                :label-text="$t('UploadSketch')"
                :file-data-type="'Scetch'"
                :file-name="createFileDataRequestDtos"
              />
              <AddFile
                ref="addFile2"
                :folder="'MaterialVariantPhoto'"
                :label-text="$t('UploadContract')"
                :file-data-type="'Contract'"
                :file-name="createFileDataRequestDtos"
              />
            </div>
            <AddFile
              ref="addFile3"
              :folder="'MaterialVariantPhoto'"
              :label-text="$t('OtherDocuments')"
              :file-data-type="'OtherDocument'"
              :file-name="createFileDataRequestDtos"
            />
          </div>
        </b-tab>
        <div class="buttonsEverywhere">
          <b-button
            v-if="tabIndex == 2"
            type="button"
            variant="none"
            class="buttonSubmit"
            :disabled="loading"
            @click="onSubmit"
          >
            <div
              v-if="loading"
              class="spinner-border spinner-border-sm"
            />
            {{ $t('Submit') }}
          </b-button>
          <b-button
            v-if="tabIndex !== 2"
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="tabIndex++"
          >
            {{ $t('Next') }}
          </b-button>
          <b-button
            v-if="tabIndex !== 0"
            type="button"
            variant="danger"
            class="buttonCancel"
            @click="tabIndex--"
          >
            {{ $t('Previous') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 0px;"
            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import vuegalleryslideshow from 'vue-gallery-slideshow';
import { mapGetters, mapActions } from 'vuex'
import AddFile from '@/modals/AddFile.vue'
import AWS from 'aws-sdk';
import { AWSurl } from '../../../domainConfig';

// import {
//   required, minLength,
// } from 'vuelidate/lib/validators';

export default {
  components: {
    vuegalleryslideshow,
    AddFile,
    // flatPickr,
    // DatePicker,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['orderNumber', 'orderId', 'clientData'],

  data() {
    return {
      shippings: {
        cost: '',
        currency: null,
        accountId: null,
        notes: '',
        paidAmount: '',
      },
      arrowCounter: 0,
      enterPressed: false,
      searchClients: '',
      clientId: '00000000-0000-0000-0000-000000000000',
      skipWatch: false,
      noSearch: false,
      clientName: null,
      loading: false,
      tabIndex: 0,
      bucketName: AWSurl.bucketName,
      index: null,
      createFileDataRequestDtos: [],
      form: {
        pickUpInStore: false,
        pickUpDate: null,
        description: null,
        orderType: null,
        orderNumber: null,
        orderId: this.orderId,
      },
      getOrderTypes: [
        { value: 'InStore', text: this.$t('InStore') },
        { value: 'Online', text: 'Online' },
        { value: 'WholeSale', text: this.$t('WholeSale') },
        { value: 'Instagram', text: 'Instagram' },
        { value: 'Whatsapp', text: 'Whatsapp' },
        { value: 'Viber', text: 'Viber' },
        { value: 'Facebook', text: 'Facebook' },
      ],
    }
  },
  computed: {
    ...mapGetters(['getLanguage', 'getAccountDetails', 'getShipmentDetailsByOrder', 'getFirstNames', 'getOrder', 'getimagesOrder']),
    orderByAdmin() {
      return this.$store.getters.getOrder
    },
  },
  watch: {
    getShipmentDetailsByOrder: {
      handler(newVal) {
        if (newVal) {
          this.shippings = {
            accountId: newVal.accountId,
            cost: newVal.cost,
            paidAmount: newVal.paidAmount,
            notes: newVal.notes,
          }
        }
      },
    },
    orderByAdmin: {
      handler(newVal) {
        this.form = {
          description: newVal.description,
          orderType: newVal.orderType,
          pickUpInStore: newVal.pickUpInStore,
          orderNumber: newVal.orderNumber,
        }
      },
    },
    clientData(value) {
      this.searchClients = value.name
      this.clientId = value.clientId
    },
    searchClients(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists();
        } else if (!this.enterPressed) {
          this.firstNames(value);
        }
      }, 500);
    },
    'shippings.cost': function (newCost) {
      if (newCost) {
        this.shippings.paidAmount = 0;
        this.shippings.currency = '€'
      }
      if (newCost < 0) {
        this.shippings.cost = 0
      }
    },
    'shippings.paidAmount': function (newCost) {
      if (newCost > this.shippings.cost) {
        this.shippings.paidAmount = 0;
      }
      if (newCost < 0) {
        this.shippings.paidAmount = 0
      }
    },
  },
  mounted() {
  },
  created() {
    AWS.config.update({
      accessKeyId: AWSurl.accessKeyId,
      secretAccessKey: AWSurl.secretAccessKey,
      region: AWSurl.region,
    });

    this.s3 = new AWS.S3();
  },
  methods: {
    ...mapActions(['deleteFile', 'resetFirstNames', 'resetCustomerData ', 'firstNames', 'getClientById', 'clearCustomMeasurements', 'getFileByOrderNumber']),
    async searchByOn(value) {
      this.skipWatch = true;
      const clientData = await this.getClientById({
        clientId: value,
        successCallback: () => {
        },
      })
      this.searchClients = `${clientData.firstName} ${clientData.lastName}`
      this.clientId = value
      this.resetFirstNames()
    },
    removeLists() {
      this.resetFirstNames();
      this.searchClients = '';
      this.clientId = '00000000-0000-0000-0000-000000000000';
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFirstNames.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFirstNames[this.arrowCounter].clientId)
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    downloadFile(fileUrl) {
      // Extract filename from the URL
      const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;

      // Trigger a click on the anchor element to start the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleDeleteClick(test) {
      console.log('valueee', test)
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this photo?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        cancelVariant: 'none',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            this.deletePhotoo(test);
          }
        })
        .catch((err) => {
          console.log('Error in msgBoxConfirm:', err);
        });
    },
    async deletePhotoo(value) {
      console.log('delete', value)
      await this.deleteFile({
        fileDataId: value,
        successCallback: () => {
          this.getFileByOrderNumber(this.orderId)
        },
      })
    },
    async onSubmit() {
      try {
        this.loading = true;
        if (this.clientId == '00000000-0000-0000-0000-000000000000') {
          this.loading = false;
          return;
        }
        if (
          (this.shippings.cost < 0 && (this.shippings.accountId === null || this.shippings.currency === null || this.shippings.notes === '' || this.shippings.paidAmount === ''))
      || (this.shippings.accountId !== null && (this.shippings.cost === '' || this.shippings.currency === null || this.shippings.notes === '' || this.shippings.paidAmount === ''))
      || (this.shippings.currency !== null && (this.shippings.cost === '' || this.shippings.accountId === null || this.shippings.notes === '' || this.shippings.paidAmount === ''))
      || (this.shippings.notes !== '' && (this.shippings.cost === '' || this.shippings.accountId === null || this.shippings.currency === null || this.shippings.paidAmount === ''))
      || (this.shippings.paidAmount < 0 && (this.shippings.cost === '' || this.shippings.accountId === null || this.shippings.currency === null || this.shippings.notes === ''))
        ) {
          this.loading = false;
          return;
        }

        if (
          this.shippings.cost === ''
      && this.shippings.accountId === null
      && this.shippings.currency === null
      && this.shippings.notes === ''
      && this.shippings.paidAmount === ''
        ) {
          this.isEmpty = true;
        } else {
          this.isEmpty = false;
        }
        await this.$refs.addFile1.uploadFile();
        await this.$refs.addFile2.uploadFile();
        await this.$refs.addFile3.uploadFile();
        const payload = {
          ...this.getOrder,
          createFileDataRequestDtos: this.createFileDataRequestDtos,
          shippingCostRequestDto: this.isEmpty ? {} : this.shippings,
          orderId: this.orderId,
          clientId: this.clientId,
        }
        this.$emit('editOrderrr', payload)
        this.loading = false;
        this.$refs.modal.hide()
        this.resetForm()
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      } finally {
        this.createFileDataRequestDtos = [];
      }
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    NextPage() {
      this.tabIndex = 1
    },
    resetForm() {
      this.form.name = ''
      this.createFileDataRequestDtos = []
      this.fileData = [];
      this.skipWatch = false
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 94%;
z-index: 2;
box-shadow: none;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.flex-form {
  display: flex;
  flex-direction: column;
}
.files{
  display: flex;
                            flex-direction: row;
                            margin-bottom: 10px;
                            background-color: rgb(207, 207, 207);
                            border: none;
                            box-shadow: none;
                            padding: 12px;
                            color: #212529;
                            border-radius: 8px;
                            width: 100%;
                            margin-right: 10px;
                            justify-content: space-between;
                            align-items: center;


}
</style>
